import { EmailInput, TelInput, TextInput } from '@designsforhealth/dfh-react-components'
import React, { useCallback, useEffect, useMemo } from 'react'
import { firstValueFrom } from 'rxjs'
import { useReCaptcha } from '../../lib/auth/hooks/useReCaptcha'
import {
  RegistrationAddressFieldValues,
  RegistrationData,
  RegistrationFieldValues,
  RegistrationPrivateLabelUnlicensedUserMetadata,
  SubmitDataValidationHandler,
  useRegistrationForm,
  useSetLoginFactory,
  validatePasswordsMatch,
  validateTerms,
} from '../../lib/registration'
import { useLoginAfterRegistration } from '../../lib/registration/request/login-after'
import {
  formDisabledStates,
  formProcessingStates,
  useRegistrationState,
} from '../../lib/registration/state'
import { LoginAfterRegistrationErrorMessage } from './LoginAfterRegistrationErrorMessage'
import { RegistrationFormAddress } from './RegistrationFormAddress'
import { RegistrationFormPasswordInputs } from './RegistrationFormPasswordInputs'
import { RegistrationFormReCaptcha } from './RegistrationFormReCaptcha'
import { RegistrationFormSimpleTerms } from './RegistrationFormSimpleTerms'
import { RegistrationFormSubmit } from './RegistrationFormSubmit'
import { RegistrationMessage } from './RegistrationMessage'
import * as Styled from './styled'
import { RegistrationUserActionRequired } from './RegistrationUserActionRequired'
import {
  COMPANY_NAME_MAX_LENGTH,
  companyNameMaxLengthRule,
  EMAIL_MAX_LENGTH,
  emailMaxLengthRule,
  FIRST_NAME_MAX_LENGTH,
  firstNameMaxLengthRule,
  LAST_NAME_MAX_LENGTH,
  lastNameMaxLengthRule,
  PHONE_MAX_LENGTH,
  phoneMaxLengthRule,
} from '../../lib/form/fieldLengthRules'
import {
  privateLabelUnlicensedRegistrationRequest$,
  setPrivateLabelUnlicensedRegistrationInput,
  useLatestPrivateLabelUnlicensedRegistrationInput,
} from '../../lib/registration/request/private-label-unlicensed'

export type RegistrationFormPrivateLabelUnlicensedProps = {
  setSubmitting?: (submitting: boolean) => void
}

type RegistrationPrivateLabelUnlicensedFieldValues = RegistrationFieldValues &
  RegistrationAddressFieldValues & {
    companyName: string
    phone: string
  }

const parseFieldValuesToRequestData = ({
  firstName,
  lastName,
  email,
  password,
  companyName,
  phone,
  street1,
  street2,
  city,
  state,
  zipCode,
  country,
}: RegistrationPrivateLabelUnlicensedFieldValues): RegistrationData &
  RegistrationPrivateLabelUnlicensedUserMetadata => {
  return {
    firstName,
    lastName,
    email,
    password,
    type: 'private_label_unlicensed',
    company_name: companyName,
    phone,
    address: JSON.stringify({
      street1,
      street2,
      city,
      state,
      zip_code: zipCode,
      country,
    }),
  }
}

const submitDataValidationHandlers: ReadonlyArray<
  SubmitDataValidationHandler<RegistrationPrivateLabelUnlicensedFieldValues>
> = [validatePasswordsMatch, validateTerms]

export const RegistrationFormPrivateLabelUnlicensed: React.VFC<RegistrationFormPrivateLabelUnlicensedProps> =
  ({
    setSubmitting = () => {
      // noop
    },
  }) => {
    useLatestPrivateLabelUnlicensedRegistrationInput()
    useLoginAfterRegistration()
    useSetLoginFactory()

    const [
      { messages, dismissMessage, patterns },
      {
        register,
        watch,
        handleSubmit,
        errors,
        control,
        formState: { isSubmitting },
      },
    ] = useRegistrationForm<RegistrationPrivateLabelUnlicensedFieldValues>({
      submitDataValidationHandlers,
    })

    const { name: registrationState } = useRegistrationState()
    const processing = isSubmitting || formProcessingStates.includes(registrationState)
    const disabled = processing || formDisabledStates.includes(registrationState)

    const { captchaError, validateCaptcha, reCaptchaProps } = useReCaptcha()

    const onSubmit = useCallback(
      async (fieldValues: RegistrationPrivateLabelUnlicensedFieldValues) => {
        const isValidCaptcha = !!validateCaptcha()
        if (!isValidCaptcha) {
          return
        }
        setPrivateLabelUnlicensedRegistrationInput({
          input: parseFieldValuesToRequestData(fieldValues),
        })
        await firstValueFrom(privateLabelUnlicensedRegistrationRequest$)
      },
      [validateCaptcha]
    )

    useEffect(() => {
      setSubmitting(processing)
    }, [setSubmitting, processing])

    const userActionRequiredMessage = useMemo(
      () => messages.find((message) => !!message.actions?.includes('user_action_required')),
      [messages]
    )

    if (userActionRequiredMessage) {
      return <RegistrationUserActionRequired message={userActionRequiredMessage} />
    }

    return (
      <Styled.RegistrationForm noValidate onSubmit={handleSubmit(onSubmit)}>
        <Styled.LegalDisclaimer>
          You may use this form if you want to create an account under your individual name, not the
          name of a registered business entity, and will purchase, on a wholesale basis, only
          private-labeled products.
          <br />
          <br />
          If you wish to establish an account under the name of a business entity, then you will
          have the opportunity to convert your account to a business entity account at any time
          after you complete registration by contacting your DFH sales representative, subject to
          additional terms and conditions. Conversion is permitted only for valid business entities
          in good standing.
        </Styled.LegalDisclaimer>
        <Styled.Title>Account Registration</Styled.Title>
        {messages.map((message, index) => (
          <RegistrationMessage
            key={index}
            message={message}
            onDismiss={() => dismissMessage(message)}
          />
        ))}
        {registrationState === 'login_error' && <LoginAfterRegistrationErrorMessage />}
        <div>
          <Styled.FieldsRow>
            <Styled.HalfColumn>
              <TextInput
                autoComplete="given-name"
                label="Owner First Name"
                name="firstName"
                register={register}
                errors={errors}
                required
                disabled={disabled}
                defaultValue=""
                maxLength={FIRST_NAME_MAX_LENGTH}
                validations={firstNameMaxLengthRule}
              />
            </Styled.HalfColumn>
            <Styled.HalfColumn>
              <TextInput
                autoComplete="family-name"
                label="Owner Last Name"
                name="lastName"
                register={register}
                errors={errors}
                required
                disabled={disabled}
                defaultValue=""
                maxLength={LAST_NAME_MAX_LENGTH}
                validations={lastNameMaxLengthRule}
              />
            </Styled.HalfColumn>
          </Styled.FieldsRow>
          <Styled.FieldsRow>
            <TextInput
              autoComplete="organization"
              label="Company Name"
              name="companyName"
              register={register}
              errors={errors}
              required
              disabled={disabled}
              defaultValue=""
              maxLength={COMPANY_NAME_MAX_LENGTH}
              validations={companyNameMaxLengthRule}
            />
          </Styled.FieldsRow>
          <Styled.FieldsRow>
            <EmailInput
              autoComplete="email"
              label="Email Address"
              name="email"
              register={register}
              errors={errors}
              required
              disabled={disabled}
              defaultValue=""
              validations={{
                ...emailMaxLengthRule,
                pattern: patterns.email,
              }}
              maxLength={EMAIL_MAX_LENGTH}
            />
          </Styled.FieldsRow>
          <Styled.FieldsRow>
            <TelInput
              autoComplete="tel"
              label="Phone Number"
              name="phone"
              register={register}
              errors={errors}
              required
              disabled={disabled}
              defaultValue=""
              maxLength={PHONE_MAX_LENGTH}
              validations={phoneMaxLengthRule}
            />
          </Styled.FieldsRow>
          <RegistrationFormPasswordInputs
            disabled={disabled}
            defaultValue=""
            errors={errors}
            register={register}
            watch={watch}
          />
          <Styled.Title>
            <Styled.TitleHelper>Business address:</Styled.TitleHelper>
          </Styled.Title>
          <RegistrationFormAddress
            disabled={disabled}
            register={register}
            errors={errors}
            watch={watch}
            control={control}
          />
          <Styled.FieldsRow $gutter>
            <RegistrationFormSimpleTerms
              register={register}
              linkComponents={[Styled.PrivacyNoticeLink, Styled.TermsOfUseLink]}
            />
          </Styled.FieldsRow>

          <RegistrationFormReCaptcha reCaptchaProps={reCaptchaProps} captchaError={captchaError} />

          <RegistrationFormSubmit disabled={disabled} processing={processing}>
            Register
          </RegistrationFormSubmit>
        </div>
      </Styled.RegistrationForm>
    )
  }
